import React, { useEffect, useState } from "react";
import { fetchUserDetails } from "../Header/Data";

const TvProductCard = ({ item }) => {
  const item_desc = item.Description;
  const title = item.Title;
  const maxLength = 80; // Set max length for the description to 50 characters
  const truncatedItemDesc =
    item_desc.length > maxLength
      ? `${item_desc.slice(0, maxLength)}...`
      : item_desc;

  if (!item) {
    return <p>Loading...</p>;
  }

  return (
    <div className="tv-product-card card my-3">
      <div className="card-container-guru">
        <h5 className="head m-0">
          {title.length > 25 ? `${title.substring(0, 24)}...` : title}
        </h5>
        <div className="product-image-container">
          <img
            className="product-image"
            src={(() => {
              try {
                const photos = JSON.parse(item.Photo);
                return Array.isArray(photos) && photos.length > 0
                  ? photos[0]
                  : "https://st3.depositphotos.com/6672868/13801/v/600/depositphotos_138013506-stock-illustration-user-profile-group.jpg";
              } catch (error) {
                console.error("Error parsing item.Photo:", error);
                return "https://st3.depositphotos.com/6672868/13801/v/600/depositphotos_138013506-stock-illustration-user-profile-group.jpg";
              }
            })()}
            alt="Product"
          />
        </div>
        <div className="text-center guru-dec">
          <a href={`tel:8002453610`} style={{}} className="text-danger">
            {/* CALL (800) 245-3610 -Ext {item?.dialExtension} */}
            Listing #{item?.dialExtension}
          </a>
          <p className="dec">{truncatedItemDesc}</p>
        </div>
      </div>
    </div>
  );
};

export default TvProductCard;
