import React, { useEffect, useState, useRef } from "react";
import { Col, Input, Row } from "reactstrap";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import {
  setSearchTerm,
  setSelectedSubCategory,
  setSelectedItem,
  setRadius,
  setFinalProducts,
  setFinalBwv,
  setActiveTab,
} from "../../../redux/slices/LcaSlice";
import { FaAngleDoubleDown, FaAngleDoubleUp } from "react-icons/fa";
import { RiArrowDownDoubleFill } from "react-icons/ri";
const LcaSideBar = ({
  items,
  productSubCat,
  scrollPopupVisible,
  setScrollPopupVisible,
  isLoading,
}) => {
  const dispatch = useDispatch();
  const {
    searchTerm,
    selectedSubCategory,
    selectedItem,
    radius,
    allProducts,
    finalProducts,
    Lca,
    activeTab,
    bwv,
    searchQuery,
  } = useSelector((state) => state.lcAds);
  const initialCategoriesToShow = 7; // Number of categories to initially show
  const popupRef = useRef(); // Ref for the popup
  const [showPopup, setShowPopup] = useState(false);
  const [visibleCategories, setVisibleCategories] = useState(
    productSubCat.slice(0, initialCategoriesToShow)
  );
  const [showAll, setShowAll] = useState(false);
  const scrollPopupRef = useRef();
  function tglhide() {
    var hdel = document.getElementById("lca-sidebar");
    hdel.classList.remove("tgl-show");
    hdel.classList.add("tgl-close");
  }
  const handleToggleShowAll = () => {
    if (showAll) {
      setVisibleCategories(productSubCat.slice(0, initialCategoriesToShow));
    } else {
      setVisibleCategories(productSubCat);
    }
    setShowAll(!showAll);
  };
  const handlePopUpClick = () => {
    window.scrollTo(0, 350);
    setScrollPopupVisible(true);
  };
  const handleSubCategoryClick = (category) => {
    handlePopUpClick();
    if (Lca) {
      if (selectedSubCategory && selectedSubCategory.id === category.id) {
        dispatch(setSelectedSubCategory(null));
        dispatch(setActiveTab("Customers"));
      } else {
        dispatch(setSelectedSubCategory(category));
      }
      dispatch(setSelectedItem(null));
    }
  };
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        scrollPopupRef.current &&
        !scrollPopupRef.current.contains(event.target)
      ) {
        setScrollPopupVisible(false);
        //window.scroll(0, 0);
      }
    };
    if (scrollPopupVisible) {
      document.addEventListener("mousedown", handleClickOutside);
    }
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [scrollPopupVisible]);
  const ScrollPopup = ({ closePopup }) => (
    <div
      ref={scrollPopupRef}
      style={{
        position: "fixed",
        bottom: "10%",
        left: "50%",
        transform: "translateX(-50%)",
        padding: "10px 20px",
        backgroundColor: "#fff",
        borderRadius: "8px",
        boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
        zIndex: 9,
        display: scrollPopupVisible ? "block" : "none",
      }}
      onClick={() => {
        closePopup();
        //window.scroll(0, 0);
      }}
    >
      <div style={{ fontSize: "16px", fontWeight: "bold", textWrap: "nowrap" }}>
        Scroll &nbsp;
        <span className="text-primary">
          UP <FaAngleDoubleUp />
        </span>
        &nbsp; / &nbsp;
        <span className="text-primary">
          DOWN <FaAngleDoubleDown />{" "}
        </span>
        to see new ads
      </div>
    </div>
  );
  useEffect(() => {
    let products;
    let vendors = bwv;
    if (
      selectedSubCategory &&
      selectedSubCategory.Name === "Upcoming Wedding/Event"
    ) {
      products = Lca.filter(
        (ad) => ad.engagedCouple === 1 || ad.engagedProfile === 1
      );
    } else {
      products = allProducts;
    }
    const apply = () => {
      if (selectedSubCategory) {
        if (selectedSubCategory.Name === "Upcoming Wedding/Event") {
          products = products;
        } else {
          products = products.filter(
            (ads) => selectedSubCategory.id === ads.subCategoryId
          );
          dispatch(setActiveTab("Customers"));
        }
      }
      if (selectedItem) {
        products = products.filter((product) => {
          const subCatItemName =
            selectedItem.subCatItemName?.trim().toLowerCase() || "";
          const itemName = product.itemName?.trim().toLowerCase() || "";
          const subCategoryName =
            product.subCategoryName?.trim().toLowerCase() || "";
          const categoryName = product.categoryName?.trim().toLowerCase() || "";
          return (
            subCatItemName === itemName ||
            subCatItemName === subCategoryName ||
            subCatItemName === categoryName
          );
        });
        vendors = vendors.filter((vendor) => {
          const subCatItemName =
            selectedItem.subCatItemName?.trim().toLowerCase() || "";
          const itemName = vendor.itemName?.trim().toLowerCase() || "";
          const subCategoryName =
            vendor.subCategoryName?.trim().toLowerCase() || "";
          const categoryName = vendor.categoryName?.trim().toLowerCase() || "";
          return (
            subCatItemName === itemName ||
            subCatItemName === subCategoryName ||
            subCatItemName === categoryName
          );
        });
      }
      if (searchTerm) {
        let filterProducts = [];
        if (
          selectedSubCategory &&
          selectedSubCategory.Name === "Upcoming Wedding/Event"
        ) {
          filterProducts = products.filter(
            (ad) => ad.engagedCouple === 1 || ad.engagedProfile === 1
          );
        } else {
          filterProducts = products.filter(
            (product) =>
              product.engagedProfile !== 1 && product.engagedCouple !== 1
          );
        }
        products = filterProducts.filter(
          (ad) =>
            ad.Zipcode &&
            ad.Zipcode.toLowerCase().includes(searchTerm.toLowerCase())
        );
      }
      if (searchQuery) {
        const trimmedQuery = searchQuery.trim().toLowerCase();
        products = products.filter((lca) => {
          const titleMatch = lca.Title?.trim()
            .toLowerCase()
            .includes(trimmedQuery);
          const subCategoryMatch = lca.subCategoryName
            ?.trim()
            .toLowerCase()
            .includes(trimmedQuery);
          const zipcodeMatch = String(lca.Zipcode)
            ?.trim()
            .toLowerCase()
            .includes(trimmedQuery);
          return titleMatch || subCategoryMatch || zipcodeMatch;
        });
        vendors = vendors.filter((bwv) => {
          const listNameMatch = bwv.listName
            ?.trim()
            .toLowerCase()
            .includes(trimmedQuery);
          const subCategoryMatch = bwv.subCategoryName
            ?.trim()
            .toLowerCase()
            .includes(trimmedQuery);
          const catMatch = bwv.categoryName
            ?.trim()
            .toLowerCase()
            .includes(trimmedQuery);
          const itemMatch = bwv.itemName
            ?.trim()
            .toLowerCase()
            .includes(trimmedQuery);
          const location = bwv.Location?.trim()
            .toLowerCase()
            .includes(trimmedQuery);
          return (
            listNameMatch ||
            subCategoryMatch ||
            catMatch ||
            itemMatch ||
            location
          );
        });
      }
    };
    apply();
    dispatch(setFinalProducts(products));
    dispatch(setFinalBwv(vendors));
  }, [selectedSubCategory, selectedItem, searchTerm, Lca, bwv, searchQuery]);

  const handleItemClick = (item, ev) => {
    dispatch(setSelectedItem(item));
    ev.stopPropagation();
  };
  useEffect(() => {
    dispatch(setRadius([1, 1]));
  }, [searchTerm]);

  const filterByLocation = () => {
    tglhide();
  };

  const handleRadiusChange = (event) => {
    dispatch(setRadius([1, event.target.value]));
  };
  const filterRadius = () => {
    console.log("resultval");
    const newradius = radius[1] * 1.60934; // Convert 30 miles to kilometers
    const apiKey = "a1120e40-5ea0-11ef-b99c-a16560773f39";
    const baseUrl = "https://app.zipcodebase.com/api/v1/radius";
    let zipCode = null;
    if (searchTerm.length === 5 || 6) {
      zipCode = searchTerm; // Replace with the appropriate zip code
    }
    const url = `${baseUrl}?apikey=${apiKey}&code=${zipCode}&radius=${newradius}&country=us`;
    axios
      .get(url)
      .then((response) => {
        console.log(response, "resultval");
        const resultval = response.data;
        const zipcodes = resultval.results;
        if (!resultval.results.error) {
          const processedZipcodes = zipcodes.map((dataval) => dataval.code);
          console.log(zipcodes, "zipcodes");
          let filterRad = [];
          if (
            selectedSubCategory &&
            selectedSubCategory.Name === "Upcoming Wedding/Event"
          ) {
            filterRad = Lca.filter(
              (ad) => ad.engagedCouple === 1 || ad.engagedProfile === 1
            );
          } else {
            filterRad = Lca.filter(
              (ad) => ad.engagedProfile !== 1 && ad.engagedCouple !== 1
            );
          }
          console.log(filterRad, "filterRad");
          const filterLcaByZipcode = filterRad.filter((ad) =>
            processedZipcodes.includes(ad.Zipcode)
          );
          console.log(filterLcaByZipcode, "filterLcaByZipcode");
          dispatch(setFinalProducts(filterLcaByZipcode));
        }
      })
      .catch((error) => {
        console.error("Error fetching zip codes:", error);
      });
  };
  useEffect(() => {
    setVisibleCategories(productSubCat.slice(0, initialCategoriesToShow));
  }, [productSubCat, initialCategoriesToShow]);
  const Popup = ({ closePopup }) => (
    <div
      className="text-center wc_show_cat_popup d-lg-none d-xl-block d-none"
      style={{
        position: "absolute",
        top: "86px",
        left: "110px",
      }}
    >
      <div
        className=""
        ref={popupRef}
        style={{
          // position: "absolute",
          // top: iPadView ? "15%" : "7%",
          // left: iPadView ? "20%" : "50%",
          // transform: "translate(-50%, -50%)",
          padding: "10px",
          backgroundColor: "rgb(246 225 107)",
          borderRadius: "8px",
          boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
          zIndex: 999,
          width: 160,
          margin: "-74px auto 10px",
          animation: "flashingRed 2s infinite",
          // animation:"bounce 2s infinite"
        }}
      >
        <div
          style={{
            fontSize: "12px",
            fontWeight: "bold",
            textWrap: "nowrap",
            position: "relative",
            marginBottom: "15px",
          }}
        >
          Select an AD type to view
        </div>
        <div
          style={{
            marginTop: "-17px",
            textAlign: "center",
            marginBottom: "-9px",
          }}
          onClick={closePopup}
        >
          <RiArrowDownDoubleFill size={25} />
        </div>
      </div>
    </div>
  );
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (popupRef.current && !popupRef.current.contains(event.target)) {
        setShowPopup(false);
      }
    };
    if (showPopup) {
      document.addEventListener("mousedown", handleClickOutside);
    }
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [showPopup]);
  useEffect(() => {
    const timer = setTimeout(() => {
      setShowPopup(true);
    }, 2500);
    return () => clearTimeout(timer);
  }, []);
  return (
    <div className="categoryList position-relative">
      {!isLoading && showPopup && (
        <Popup closePopup={() => setShowPopup(false)} />
      )}
      <span
        onClick={tglhide}
        className="d-block d-lg-block d-xl-none text-end tgl-filter-close"
        id="tgl-filter-close"
      >
        X
      </span>
      {/* {scrollPopupVisible && (
        <ScrollPopup closePopup={() => setScrollPopupVisible(false)} />
      )} */}
      <h5 className="py-4 mb-0">Category</h5>
      <ul
        style={{
          marginBottom: "10px",
          backgroundColor: "#ffffff",
          color: "#000000",
          borderRight: "1px solid rgb(224, 224, 224)",
          padding: "5px 5px 0px 0px",
          height: "100%",
        }}
        className="side-bar"
      >
        {visibleCategories.map((category) => (
          <li
            key={category.id}
            onClick={() => handleSubCategoryClick(category)}
            className={`${selectedSubCategory === category ? "selected" : ""}`}
            style={{
              cursor: "pointer",
              listStyleType: "none",
              borderBottom: "1px solid rgb(224, 224, 224)",
              borderRadius: "0px 25px 0px 25px",
              padding: "15px",
              fontWeight: "bold",
            }}
          >
            {category.Name}
            {selectedSubCategory === category && (
              <div className="items-list ">
                <ul>
                  {items
                    .filter((item) => item.subCatId === category.id)
                    .map((item) => (
                      <li
                        key={item.id}
                        // eslint-disable-next-line no-restricted-globals
                        onClick={(event) => handleItemClick(item, event)}
                        className={`${
                          selectedItem === item ? "selected-item" : ""
                        }`}
                        style={{ listStyleType: "none" }}
                      >
                        {item.subCatItemName}
                      </li>
                    ))}
                </ul>
              </div>
            )}
          </li>
        ))}
      </ul>
      <div
        className="show-all-text"
        style={{
          color: "blue",
          textAlign: "right",
          cursor: "pointer",
        }}
        onClick={handleToggleShowAll}
      >
        {/* {showAll && productSubCat.length > initialCategoriesToShow ? "Show Less" : "Show All"} */}
        {showAll && productSubCat.length > initialCategoriesToShow
          ? "Show Less"
          : productSubCat.length === initialCategoriesToShow
          ? null
          : "Show All"}
      </div>

      {activeTab === "Customers" && (
        <div>
          <div className="location-filter">
            <h6 style={{ marginBottom: "10px", fontWeight: "bold" }}>
              Zipcode:
            </h6>
            <Row>
              <Col xs={8} md={8} xl={12} sm={12} lg={12}>
                <Input
                  placeholder="Search Your Zipcode.."
                  onChange={(e) => {
                    dispatch(setSearchTerm(e.target.value));
                  }}
                  value={searchTerm}
                  type="search"
                  pattern="[0-9]*"
                  onKeyDown={(e) => {
                    if ((e.keyCode < 48 || e.keyCode > 57) && e.keyCode !== 8) {
                      e.preventDefault();
                    }
                  }}
                />
              </Col>
              <Col xs={4} md={4} className=" d-lg-none d-xl-none p-0">
                <button
                  className="btn btn-primary mobile-only m-0"
                  onClick={filterByLocation}
                >
                  Search
                </button>
              </Col>
            </Row>
          </div>
          {searchTerm && (
            <div className="radius-slider">
              <h6 style={{ marginBottom: "10px" }}>Radius:</h6>
              <Input
                placeholder="Radius"
                type="range"
                min={1}
                max={50}
                onChange={handleRadiusChange}
                onMouseUp={filterRadius}
                onTouchEnd={filterRadius}
                value={radius[1]}
                className="w-100"
                step={1}
              />
              <p>{radius[1]} miles</p>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default LcaSideBar;
